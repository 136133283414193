import React from "react"
import {graphql, Link} from "gatsby"
import styled from "@emotion/styled"
import {GatsbyImage, getImage} from "gatsby-plugin-image"

import theme from "../../styles/theme"
import Layout from "../../components/layout"
import slugify from "../../services/slugify"

const Blog = ({
                  data: {
                      allContentfulBlog: {nodes: blogs},
                  },
              }) => {
    return (<Layout>
        <StyledWrapper>
            <StyledInnerWrapper className="inner center text-center">
                <div className="margin-bottom">
                    <h2>
                        <em>Le blog</em> <em className="variant">qui recycle</em>
                    </h2>
                </div>

                <div className="grid cols-3">
                    {blogs.map(blog => {
                        const {id, title, image, createdAt, author} = blog
                        const slug = slugify(title)
                        const pathToImage = getImage(image)
                        const createdAtDate = new Date(createdAt)
                        return (<StyledCard key={id}>
                            <StyledLink to={slug}>
                                <GatsbyImage
                                    image={pathToImage}
                                    alt={title}
                                    placeholder="blurred"
                                />
                                <StyledTitleWrapper>
                                    <StyledTitle className="marginless">{title}</StyledTitle>
                                    <StyledAuthor>
                                        {createdAtDate.getFullYear()}/
                                        {createdAtDate.getMonth() + 1}/
                                        {createdAtDate.getUTCDate()} - {author}
                                    </StyledAuthor>
                                </StyledTitleWrapper>
                            </StyledLink>
                        </StyledCard>)
                    })}
                </div>
            </StyledInnerWrapper>
        </StyledWrapper>
    </Layout>)
}

export const query = graphql`
  {
    allContentfulBlog(
      sort: { fields: createdAt, order: ASC }
      filter: { featured: { eq: true } }
    ) {
      nodes {
        id
        title
        image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
        createdAt
        author
      }
    }
  }
`

const StyledWrapper = styled.section`
  margin-top: 2em;
  padding-top: calc(2em + ${theme.layout.headerHeight});
  display: flex;
`

const StyledInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const StyledTitle = styled.h3`
  color: ${theme.palette.hightlight[0]};
  font-size: ${theme.typography.h5};
  @media only screen and (min-width: ${theme.breakpoint.medium}px) {
    font-size: 1em;
  }
`

const StyledAuthor = styled.span`
  color: ${theme.palette.common.black};
`

const StyledTitleWrapper = styled.div`
  height: 9em;
  padding: 0.5em;
`

const StyledCard = styled.div`
  background-color: ${theme.palette.common.white};
  border-radius: ${theme.shape.borderRadius[1]};
  box-shadow: ${theme.elevation.level3};
  overflow: hidden;
  margin-bottom: 2em;
  @media only screen and (min-width: ${theme.breakpoint.medium}px) {
    margin-bottom: 0;
  }
`

export default Blog
